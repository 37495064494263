<template>
  <a-layout style="height: 100%">
    <!-- <a-layout-header>
      <header class="ant-layout-header sc-dMUtCB dicbOj">
        <div></div>
        <div class="sc-nLyvy hrYyqu">
          <div
            id="download_counter"
            class="sc-jnhPJk ftaimI"
            style="margin-right: 32px"
          >
            <svg
              viewBox="0 0 1024 1024"
              width="22"
              height="22"
              fill="currentColor"
            >
              <path d="M464 0.64h96v704h-96z"></path>
              <path
                d="M868.48 1024H154.88A155.52 155.52 0 0 1 0 868.48V576h99.2v291.2a55.68 55.68 0 0 0 55.68 55.68h713.6a55.68 55.68 0 0 0 56.32-55.68V576H1024v291.2A155.52 155.52 0 0 1 868.48 1024z"
              ></path>
              <path
                d="M512 757.12a48 48 0 0 1-33.92-14.08L123.52 388.48 192 320l320 320 320-320 67.84 67.84-353.92 355.2a48 48 0 0 1-33.92 14.08z"
              ></path>
            </svg>
          </div>
          <div class="sc-gCxsRJ eNfCYM ant-dropdown-trigger">
            <span
              class="ant-avatar ant-avatar-circle ant-avatar-icon"
              style="
                width: 28px;
                height: 28px;
                line-height: 28px;
                font-size: 14px;
                flex-shrink: 0;
              "
              ><svg
                viewBox="0 0 1024 1024"
                width="28"
                height="28"
                fill="#608FFF"
              >
                <path
                  d="M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z"
                ></path>
                <path
                  d="M824.888889 805.831111a312.888889 312.888889 0 0 0-113.777778-240.64l-14.791111-11.377778a318.862222 318.862222 0 0 0-56.888889-32.142222 227.555556 227.555556 0 0 0-38.115555-14.222222 149.902222 149.902222 0 0 0 16.782222-10.524445 190.862222 190.862222 0 0 0 56.888889-252.302222 182.897778 182.897778 0 0 0-32.142223-40.106666 195.128889 195.128889 0 0 0-332.231111 134.826666 192.568889 192.568889 0 0 0 100.693334 170.666667 312.888889 312.888889 0 0 0-221.582223 296.391111 24.462222 24.462222 0 0 0 23.893334 24.462222h586.24a24.462222 24.462222 0 0 0 23.893333-24.746666z"
                  fill="#FFFFFF"
                ></path></svg
            ></span>
            <div class="text-container">
              <div style="display: flex; align-items: center">
                <div class="username">
                  <div class="sc-hLBbgP YUoiF">
                    <div class="sc-hLBbgP hQFEjz">demo_188</div>
                  </div>
                </div>
              </div>
              <div class="company-name text-ellipsis">SalesDemo</div>
            </div>
          </div>
        </div>
      </header>
    </a-layout-header> -->
    <a-layout-content style="overflow-y: auto; overflow-x: hidden;">
      <router-view />
    </a-layout-content>
  </a-layout>
</template>

<script>
export default {
  data() {
    return {
      openKeys: [],
    };
  },
};
</script>

<style scoped>
.ant-layout-header {
  background-color: #fff;
}
.ant-layout-header {
  height: 55px;
  line-height: 55px;
  padding: 0;
}
.ant-layout-sider {
  max-width: 150px !important;
  min-width: 150px !important;
  width: 150px !important;
  border-right: 1px solid #f0f0f0;
}

.header-title {
  height: 55px;
  color: rgba(43, 69, 95, 0.698);
  line-height: 55px;
  text-align: center;
  border-bottom: 1px solid #f0f0f0;
}
.dicbOj {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: #fff;
  padding: 0;
  height: 55px;
}
.hrYyqu{
  padding-right: 20px;
  width: 550px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.eNfCYM {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}
.eNfCYM .text-container {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin-left: 12px;
  margin-right: 20px;
  height: 32px;
}
.eNfCYM .company-name {
  font-size: 12px;
  line-height: 14px;
  color: #6f7d8f;
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  transform-origin: left;
}
.hQFEjz {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #1F3041;
  cursor: unset;
  direction: ltr;
  height: 18px;
  line-height: 18px;
}
.ftaimI {
    position: relative;
    width: 22px;
    height: 22px;
    line-height: 22px;
    color: #6F7D8F;
    cursor: pointer;
}
</style>